<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="tech-service" no-body>

    <template v-if="add || edit">
      <Form @updateTodayCount="getTodayCheckupsCount"/>
    </template>

    <template v-else-if="docs.classificator">
      <Classificator/>
    </template>

    <template v-else-if="docs.financial">
      <Financial/>
    </template>

    <template v-else-if="docs.fuel">
      <Fuel/>
    </template>

    <template v-else-if="docs.count">
      <Count/>
    </template>

    <template v-else-if="docs.info">
      <Info/>
    </template>

    <template v-else-if="docs.individual">
      <Individual/>
    </template>

    <template v-else-if="docs.company">
      <Company/>
    </template>

    <template v-else-if="docs.entrepreneur">
      <Entrepreneur/>
    </template>

    <template v-else-if="docs.amounts">
      <Amounts/>
    </template>

    <template v-else-if="docs.operators">
      <Operators/>
    </template>

    <template v-else>
      <b-row class="pageContent">
        <b-col class="filtersContainer" md="12">
          <div class="filters">

            <div class="filter-column">
              <v-select
                  @input="getCheckups"
                  placeholder="Որոնում"
                  id="vue-select-type"
                  :reduce="prefix => prefix.id"
                  v-model="filters.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="search_filters_top"
                  class="searchTypeSelect"
                  :clearable="false"
              />
            </div>

            <div class="filter-column">
              <b-form-input
                  autocomplete="off"
                  @change="getCheckups"
                  @keydown="disableSymbol($event)"
                  v-model="filters.search"
                  id="owner_name"
                  type="text"
                  placeholder="Փնտրել..."
                  class="w-full text-uppercase opinionFilter"
                  clearable
              />
            </div>

          </div>
        </b-col>
        <b-col>
          <ag-grid-vue
              @gridReady="onGridReady"
              ref="agGridTable"
              :localeText="localization"
              :components="components"
              :gridOptions="gridOptions"
              :class="table_class"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="checkups"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="50"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
          </ag-grid-vue>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0 pb-0 align-items-center">

            <!-- page length -->
            <strong class="black">
              {{ pagination.total }} արդյունք
            </strong>

            <!-- pagination -->
            <div>
              <b-pagination
                  @input="changePage"
                  v-model="pagination.current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.count"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </template>

    <!--Search Modal-->
    <b-modal
        ref="search_modal"
        id="modal-search"
        cancel-variant="outline-secondary"
        ok-title="Ընտրել"
        centered
        size="xl"
        title="Որոնել"
        :is-valid="false"
        :hide-footer="true"
        @hide="hideSearchModal"
    >

      <div class="search-modal-header">
        <div class="search-modal-header">
<!--          <div class="search-header-column">-->
<!--            <MazSelect-->
<!--                :dark="isDark"-->
<!--                v-model="form.filter"-->
<!--                placeholder="Փնտրել ըստ"-->
<!--                class="w-full mb-1"-->
<!--                :options="search_filters"-->
<!--                old-left-icon-name="filter_alt"-->
<!--            />-->
<!--          </div>-->
          <div class="search-header-column">
            <MazInput
                :dark="isDark"
                autofocus
                @input="formSubmited = false"
                type="text"
                @keydown="disableSymbol($event)"
                v-model="form.value"
                placeholder="Փնտրել..."
                autocomplete="new-search"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="search">
            </MazInput>
          </div>
          <div class="search-header-column">
            <b-button
                :disabled="submited"
                @click="searchCheckups"
                variant="outline-primary"
                class="btn-icon searchSubmitButton"
            >
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>

        <div class="search-modal-header">
          <div class="search-header-column">
            <MazInput
                :dark="isDark"
                autofocus
                type="text"
                v-model="DateFormSearch"
                placeholder="Տեխզննման ժամկետի ստուգում..."
                autocomplete="new-search"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="search">
            </MazInput>
          </div>
          <div style="min-width: auto;" class="search-header-column">
            <b-button
                @click="findCheckupDate"
                variant="outline-primary"
                class="btn-icon searchSubmitButton"
            >
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
          <div class="search-header-column">
            <div class="search-info">
              <b-button
                  class="tooltipButton"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  v-b-tooltip.hover.v-dark
                  title="Տեղեկատվությունը ստուգվում է վերջին երեք տարվա ժամանակահատվածի համար"
                  variant="outline-dark">
                <fa-icon icon="info-circle" />
              </b-button>
            </div>
          </div>
        </div>

      </div>

      <div class="search-modal-body">
        <div v-if="errorMessage">
          <b-alert class="mt-2 p-1" show variant="danger">{{ errorMessage }}</b-alert>
        </div>
        <div v-if="search_result.length === 0 && form.value !== ''">
<!--          <b-alert-->
<!--              v-if="formSubmited"-->
<!--              variant="danger"-->
<!--              show-->
<!--          >-->
<!--            <div class="alert-body">-->
<!--              <span>Տվյալների ավտոմատ ստացումը չհաջողվեց։</span>-->
<!--            </div>-->
<!--          </b-alert>-->

        </div>
        <b-table
            v-else
            striped
            hover
            responsive
            class="position-relative"
            :items="search_result"
            :fields="fields"
        >
          <template #cell(action)="data">
            <b-button @click="copyCheckup(data.item, true)" variant="primary" size="sm">
              <feather-icon
                  icon="CopyIcon"
                  class="mr-50"
              />
              <span class="align-middle">Կրկնօրինակել</span>
            </b-button>
<!--            <b-button v-if="data.item.company._id === companyID" @click="openCheckNum(data.item)" class="ml-1"-->
<!--                      variant="primary" size="sm">-->
<!--              <feather-icon-->
<!--                  icon="PlusIcon"-->
<!--              />-->
<!--            </b-button>-->
<!--            <b-button @click="openClassificatorDoc(data.item)"  variant="primary" size="sm" class="ml-1">-->
<!--              <feather-icon-->
<!--                  icon="PrinterIcon"-->
<!--                  class="mr-50"-->
<!--              />-->
<!--            </b-button>-->
          </template>
          <template #cell(payment)="data">
            <template v-if="data.item.payment">
              <div class="payment-services">
                <div @click="openReciept(data.item.payment, item)"  class="service" v-for="(item, index) in data.item.payment.items" :key="index">
                  <fa-icon icon="receipt" />
                  <span class="name">{{item.service.translations.hy.name}}</span>
                </div>
              </div>
            </template>
          </template>
          <template #cell(pays)="data">
            <template>
              <div style="width: 35px; height: 35px; border-radius: 8px;" @click="openPaymentsInfo(data.item)" class="can-make-checkup can-make-checkup-1">
                $
              </div>
            </template>
          </template>
<!--          <template #cell(validDate)="data">-->
<!--            {{ data.value | formatDate }}-->
<!--          </template>-->
          <template #cell(car_model)="data">
            {{ data.item.car }} {{ data.item.car_model }}
          </template>
          <template #cell(taxes)="data">
            <template v-if="data.item.taxes && data.item.taxes.decision && data.item.taxes.decision.inspection">
              <div  @click="openTaxesInfo(data.item.taxes)" class="can-make-checkup can-make-checkup-1">
                <fa-icon icon="check-circle" />
              </div>

            </template>
            <template v-else>
              <div @click="openTaxesInfo(data.item.taxes)" class="can-make-checkup can-make-checkup-0">
                <fa-icon icon="info-circle" />
              </div>
            </template>
            <template v-if="!data.item.dahk_status">
              <div  class="mt-1 animate-flicker can-make-checkup can-make-checkup-0">
                ԴԱՀԿ հետախուզում
              </div>
            </template>
          </template>
          <template #cell(dahk_exists)="data">
            <template v-if="data.item.dahk_exists">
              <div  class="can-make-checkup can-make-checkup-1">
                <fa-icon icon="check-circle" />
              </div>

            </template>
            <template v-else>
              <div  class="can-make-checkup can-make-checkup-0">
                <fa-icon icon="info-circle" />
              </div>
            </template>
          </template>
        </b-table>

        <div v-if="date_search_result.length === 0 && DateFormSearch !== ''">
          <b-alert
              v-if="dateFormSubmited"
              variant="danger"
              show
          >
            <div class="alert-body">
              <span>Շտեմարանում {{ DateFormSearch.toLocaleUpperCase() }} վերաբերյալ տեղեկատվությունը բացակայում է:</span>
            </div>
          </b-alert>

        </div>
        <b-table
            v-else-if="date_search_result.length > 0"
            striped
            hover
            responsive
            class="position-relative"
            :items="date_search_result"
            :fields="dateFields"
        >
          <template #cell(action)="data">

            <b-button @click="copyCheckup(data.item)" variant="primary" size="sm">
              <feather-icon
                  icon="CopyIcon"
                  class="mr-50"
              />
              <span class="align-middle">Կրկնօրինակել</span>
            </b-button>

            <b-button v-if="data.item.company._id === $store.state.user.company" @click="openClassificatorDoc(data.item)"  variant="primary" size="sm" class="ml-1">
              <feather-icon
                  icon="PrinterIcon"
                  class="mr-50"
              />
            </b-button>
          </template>
          <template #cell(payment)="data">
            <template v-if="data.item.payment">
              <div class="payment-services">
                <div @click="openReciept(data.item.payment, item)"  class="service" v-for="(item, index) in data.item.payment.items" :key="index">
                  <fa-icon icon="receipt" />
                  <span class="name">{{item.service.translations.hy.name}}</span>
                </div>
              </div>
            </template>
          </template>
          <template #cell(taxes)="data">
            <template v-if="data.item.taxes && data.item.taxes.decision && data.item.taxes.decision.inspection">
              <div  @click="openTaxesInfo(data.item.taxes)" class="can-make-checkup can-make-checkup-1">
                <fa-icon icon="check-circle" />
              </div>

            </template>
            <template v-else>
              <div @click="openTaxesInfo(data.item.taxes)" class="can-make-checkup can-make-checkup-0">
                <fa-icon icon="info-circle" />
              </div>
            </template>
            <template v-if="!data.item.dahk_status">
              <div  class="mt-1 animate-flicker can-make-checkup can-make-checkup-0">
                ԴԱՀԿ հետախուզում
              </div>
            </template>
          </template>
          <template #cell(createdAt)="data">
            {{  data.value | formatDate }}
          </template>
          <template #cell(createdAt1)="data">
            {{  nextCheckupDate(data) | formatDate }}
          </template>
          <template #cell(car_model)="data">
            {{ data.item.car }} {{ data.item.car_model }}
          </template>
        </b-table>

        <b-col
            v-if="submited"
            style="width: 20%; margin: 0 auto; text-align: center; font-weight: bold; color: #000; position: relative; top: 20px;"
        >
          <div style="margin-bottom: 10px" class="progress-title">
            {{ in_progress_text }}
          </div>
          <vm-progress
              :percentage="progress_percentage"
              :text-inside="true"
              :stroke-width="18"
              status="success"
              :striped="true"
          />
        </b-col>
      </div>

    </b-modal>

    <b-modal
        ref="search_modal-mobile"
        id="modal-search-mobile"
        cancel-variant="outline-secondary"
        ok-title="Ընտրել"
        centered
        size="sm"
        title="Փնտրել մեքենա"
        :is-valid="false"
        :hide-footer="true"
        @hide="hideSearchModal"
    >
      <template #modal-header="{ ok, cancel, hide }">
        <div class="icon" @click="clearSearchResult">
          <svg v-if="search_result.length > 0" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
        </div>
        <span variant="info" style="color: #000; font-weight: bold;">Փնտրել մեքենա</span>
        <div @click="closeMobileModal" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </div>
      </template>
      <div v-if="search_result.length === 0" class="d-flex flex-column">
        <div class="search-header-column">
          <MazInput
              style="width: 86%;margin: 0 auto;"
              :dark="isDark"
              autofocus
              @input="formSubmited = false"
              type="text"
              @keydown="disableSymbol($event)"
              v-model="form.value"
              placeholder="00XX000"
              autocomplete="new-search"
              class="mb-1 text-uppercase mobileSearchInputTS"
              old-left-icon-name="search">
          </MazInput>
        </div>
        <div class="search-header-column d-flex">
          <b-button
              style="width: 86%;margin: 0 auto;    height: 37.2px;"
              :disabled="submited"
              @click="searchCheckups"
              class="btn-icon searchSubmitButton mobileSearchButtonTS"
          >
            <feather-icon icon="SearchIcon"/>
            <span style="margin-left: 5px; position: relative; top: -1px;">Որոնել</span>
          </b-button>
        </div>
      </div>
      <div class="search-modal-body">
        <div v-if="search_result && search_result.length > 0">
          <div class="car-info">
            <div class="name">{{ search_result[0].car }} {{ search_result[0].car_model }}</div>
            <div class="day">{{ search_result[0].validDate}} </div>
          </div>
          <div class="checks-info">
            <div class="check">
              <div class="check-item">
                <div v-if="search_result[0].taxes && search_result[0].taxes.decision && search_result[0].taxes.decision.inspection" class="can-make-checkup can-make-checkup-1">
                  <fa-icon icon="check-circle" />
                </div>
                <div v-else class="can-make-checkup can-make-checkup-0" @click="openTaxesInfo(search_result[0].taxes)">
                  <fa-icon icon="check-circle" />
                </div>
              </div>
              <div class="check-item">
                <div v-if="search_result[0].dahk_exists" class="can-make-checkup can-make-checkup-1">
                  <fa-icon icon="check-circle" />
                </div>
                <div v-else class="can-make-checkup can-make-checkup-0">
                  <fa-icon icon="check-circle" />
                </div>
              </div>
            </div>
            <div class="check">
              <div class="check-item">
                <div v-if="search_result[0].taxes && search_result[0].taxes.decision && !search_result[0].taxes.decision.inspection" @click="openTaxesInfo(search_result[0].taxes)" class="can-make-checkup can-make-checkup-0">
                  Գույքահարկ
                </div>
              </div>
              <div class="check-item">
                <div v-if="!search_result[0].dahk_status" class="can-make-checkup can-make-checkup-0">
                  ԴԱՀԿ
                </div>
              </div>
            </div>
          </div>
          <div v-if="search_result[0].vehicleTypes.length > 1" class="vehicleTypes">
            <div v-for="type in search_result[0].vehicleTypes" @click="setSelectedType(type)" class="type" :class="type.id === search_result[0].vehicleType ? 'active': ''">
              {{ type.name }}
            </div>
          </div>
          <div class="copy">
            <b-button style="height: 37.2px; display: flex; justify-content: center; align-items: center; padding: 0;" :disabled="!search_result[0].vehicleType" @click="copyCheckup(search_result[0])" size="md">
              <span class="align-middle">Կրկնօրինակել</span>
            </b-button>
          </div>
        </div>
        <b-col
            v-if="submited"
            style="margin: 0 auto; margin-bottom: 35px; text-align: center; font-weight: bold; color: #000; position: relative; top: 20px;"
        >
          <div style="margin-bottom: 10px" class="progress-title">
            {{ in_progress_text }}
          </div>
          <vm-progress
              :percentage="progress_percentage"
              :text-inside="true"
              :stroke-width="18"
              status="success"
              :striped="true"
          />
        </b-col>
      </div>

    </b-modal>

    <b-modal
        ref="search_payment_modal"
        id="modal-payment-search"
        cancel-variant="outline-secondary"
        ok-title="Ընտրել"
        centered
        size="xl"
        title="Որոնել"
        :is-valid="false"
        :hide-footer="true"
        @hide="hideSearchPaymentModal"
    >

      <div class="search-modal-header">
        <div class="search-header-column">
          <MazInput
              :dark="isDark"
              autofocus
              @input="formSubmited = false"
              @change="searchPaymentCheckups"
              type="text"
              @keydown="disableSymbol($event)"
              v-model="form.value"
              placeholder="Փնտրել..."
              autocomplete="new-search"
              class="w-full mb-1 text-uppercase"
              old-left-icon-name="search">
          </MazInput>
        </div>
        <div class="search-header-column-2">
          <b-button
              @click="searchPaymentCheckups"
              variant="outline-primary"
              class="btn-icon searchSubmitButton"
          >
            <feather-icon icon="SearchIcon"/>
          </b-button>
        </div>

      </div>

      <div class="search-modal-body">
        <div v-if="search_payment_result.length === 0 && form.value !== ''">
<!--          <b-alert-->
<!--              v-if="formSubmited"-->
<!--              variant="danger"-->
<!--              show-->
<!--          >-->
<!--            <div class="alert-body">-->
<!--              <span>Տվյալների ավտոմատ ստացումը չհաջողվեց։</span>-->
<!--            </div>-->
<!--          </b-alert>-->

        </div>
        <b-table
            v-else
            striped
            hover
            responsive
            class="position-relative"
            :items="search_payment_result"
            :fields="fields_payments"
        >
          <template #cell(action)="data">
            <b-button @click="copyPaymentCheckup(data.item)" variant="primary" size="sm">
              <feather-icon
                  icon="CopyIcon"
                  class="mr-50"
              />
              <span class="align-middle">Ավելացնել</span>
            </b-button>
          </template>
          <template #cell(payment)="data">
            <template v-if="data.item">
              <div class="payment-services">
                <div @click="openReciept(data.item, item)"  class="service" v-for="(item, index) in data.item.items" :key="index">
                  <fa-icon icon="receipt" />
                  <span class="name">{{item.service.translations.hy.name}}</span>
                </div>
              </div>
            </template>
          </template>
          <template #cell(created_at)="data">
            {{ data.value | formatDate }}
          </template>

          <template #cell(car_model)="data">
            {{ data.item.request.car }}
          </template>
          <template #cell(MadeAt)="data">
            {{ data.item.request.made_at }}
          </template>
          <template #cell(CarRegNo)="data">
            {{ data.item.request.car_reg_no }}
          </template>
        </b-table>
      </div>

    </b-modal>

    <b-modal
        id="modal-ask-to-edit"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Ներկայացնել խմբագրման"
        cancel-title="Կասեցնել"
        centered
        no-cancel
        size="sm"
        :title="edit_confirmed_title"
        :is-valid="false"
        @ok="confirmAskToEdit"
        :ok-disabled="notification.content === '' || notification.content === null"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
              id="textarea"
              v-model="notification.content"
              placeholder="Հաղորդագրություն"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        id="modal-add-c-number"
        :no-close-on-backdrop="true"
        centered
        size="sm"
        title="Կտրոնի կրկնօրինակ"
        :is-valid="false"
    >


      <b-form>
        <b-form-group v-if="selectedCheckup">

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table b-table table-striped table-hover"
                   id="__BVID__139"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Կտրոնի համար</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->
              <tr v-if="selectedCheckupNumbers.length === 0" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ selectedCheckup.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ selectedCheckup.CheckNo }}</td>
              </tr>
              <tr v-for="num in selectedCheckupNumbers" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ num.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ num.number }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <validation-observer ref="checkupNumberValidation">

            <validation-provider
                #default="{ errors }"
                rules="required|length:7"
            >

              <MazInput
                  id="formCheckNo"
                  :dark="isDark"
                  type="text"
                  name="email"
                  v-model="newCheckupNumber"
                  :error="!!errors.length"
                  placeholder="Կտրոնի համարը"
                  autocomplete="new-email"
                  class="w-full mb-1"
                  v-mask="'#######'"
                  old-left-icon-name="format_list_numbered"
              />

            </validation-provider>

            <hr>
            <b-button @click="addNewCheckupNumber" variant="info">
              Ավելացնել
            </b-button>

          </validation-observer>

        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="modal-add-c1-number"
        :no-close-on-backdrop="true"
        centered
        size="sm"
        title="Կտրոնի կրկնօրինակ"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table b-table table-striped table-hover"
                   id="__BVID__139"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Կտրոնի համար</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="num in checkupNumbers" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ num.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ num.number }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="modal-checkup-history"
        :no-close-on-backdrop="true"
        centered
        size="lg"
        class="modal-responsive"
        title="Պատմություն"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table mb-2 b-table table-striped table-hover"
                   id="__BVID__140"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Մոդերատոր</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Գործողույուն</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նախկին տվյալ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նոր տվյալ</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="history in checkupHistory" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ history.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ (history.user) ? history.user.name : '' }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.note }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.old }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.new }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="receipt-modal"
        modal-class="modal-primary"
        centered
        size="sm"
        :hide-footer="true"
        title=""
    >
      <b-card-text v-if="selectedService">
        <span
            class="receipt-close"
            @click="$bvModal.hide('receipt-modal')"
        >×</span>
        <div class="receipt-header">
          <div class="bank-logo">
            <img :src="require('@/assets/images/logo/evoca.png')">
          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Գործարք/ամսաթիվ
            </div>
            <div class="info">
              N {{ selectedPayment.id }} / {{ selectedPayment.created_at | formatDate }}
            </div>
          </div>
        </div>
        <div class="receipt-body">
          <div class="order-info receipt-column">
            <div class="info">
              Սեփականատեր
            </div>
            <div class="info">
              {{ selectedPayment.name }}
            </div>

          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Մեքենա
            </div>
            <div class="info">
              {{ selectedPayment.request.car }}
            </div>
          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Հաշվ․ համարանիշ
            </div>
            <div class="info">
              {{ selectedPayment.request.car_reg_no }}
            </div>
          </div>
        </div>
        <div
            v-if="selectedService.service.name === 'TechnicalInspection'"
            class="receipt-station"
        >
          <div class="order-info receipt-column">
            <div class="info">
              ՏԶ կայան
            </div>
            <div class="info">
              {{ selectedPayment.partner.translations.hy.name }}
            </div>
          </div>
        </div>
        <div class="receipt-service">
          <div class="order-info receipt-column">
            <div class="info">
              {{ selectedService.service.translations.hy.name }}
            </div>
            <div class="info">
              {{ selectedService.amount }} դրամ․
            </div>
          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Միջնորդավճար
            </div>
            <div class="info">
              {{ selectedService.commission }} դրամ․
            </div>
          </div>
        </div>
        <div class="receipt-total">
          <div class="order-info receipt-column">
            <div class="info">
              Ընդամենը վճարվել է
            </div>
            <div class="info">
              {{ selectedService.amount + selectedService.commission }} դրամ․
            </div>
          </div>
        </div>
        <div class="receipt-footer">
          <div
              class="print"
              @click="printReceipt"
          >
            Տպել
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
        id="modal-taxes-info"
        ok-only
        :hide-footer="true"
        modal-class="modal-primary"
        centered
        title="Գույքահարկի պարտավորություններ"
    >
      <b-card-text>
        <div class="taxes-info">
          <div v-for="(service, index) in taxesInfo.services"  :key="index" class="tax-info">
            <template>
              <div class="persons">
                <div v-for="(person, personIndex) in service.persons" :key="personIndex" class="person">
                  <div class="personInfo" :class="(taxesInfo && taxesInfo.decision && taxesInfo.decision.inspection) ? 'success' : ''">
                    <template v-if="person.first_name">
                      <span>{{ person.first_name }} {{ person.last_name}}</span>
                      <span v-if="service.service.vehicle_number"> - {{ service.service.vehicle_number }}</span>
                      <span v-if="service.service.cert_num">({{ service.service.cert_num }})</span>
                    </template>
                    <template v-else>
                      <span>{{ person.organization_name }}</span>
                      <span v-if="service.service.vehicle_number"> - {{ service.service.vehicle_number }}</span>
                      <span v-if="service.service.cert_num">({{ service.service.cert_num }})</span>
                    </template>

                  </div>
                  <table class="taxes">
                    <thead>
                      <tr class="tax tax-header">
                        <td class="column">Տարեթիվ</td>
                        <td class="column">Գույքահարկ</td>
                        <td class="column">Պարտք</td>
                        <td class="column">Տույժ/Տուգանք</td>
                        <td class="column">Հավելավճար</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tax, year) in person.taxes" :key="year" class="tax">
                        <td class="column">{{ year }}</td>
                        <td class="column">{{ tax.tax.amount }}</td>
                        <td class="column">{{ tax.tax.debt }}</td>
                        <td class="column">{{ (tax.penalty) ? tax.penalty.debt : 0 }}</td>
                        <td class="column">{{ tax.overpay }}</td>
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
        id="modal-payment-info"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered
        size="md"
        title=""
        :is-valid="false"
        :ok-disabled="true"
    >
      <b-form v-if="searchResult" @submit.prevent>
        <div class="payment-info">
          <div class="car">{{ searchResult.car }} {{ searchResult.car_model }}</div>
          <div v-if="payments" class="payments">
            <div v-for="(payment, type) in payments" class="payment">
              <div class="payment-items">
                <div v-for="(item, index) in payment.info" class="item" :class="(payment.info.length === 3 && index === 1)? '' : 'withDashes'">
                  <div class="title">{{ item.name }}</div>
                  <div class="amount">{{ item.amount > 0 ? `${item.amount} դր.` : '-' }} </div>
                </div>
              </div>
            </div>
            <div class="totalAll">
              Ընդամենը՝ {{ totalPayments(payments) }} դր.
            </div>
          </div>

          <div v-else>
            <div class="vehicleTypes">
              <div v-for="type in vehicleTypes" class="vehicleType">
                <div @click="selectVehicleType(type)" class="type">
                  {{ type.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        &nbsp;
      </template>
    </b-modal>

    <b-modal
        id="fm-modal"
        :no-close-on-backdrop="true"
        centered
        :hide-footer="true"
        size="md"
        title="Վերականգնում"
    >
      <div class="search-bar" style="    min-height: 200px;">
        <b-row>
          <b-col md="6">
            <MazInput
                :dark="isDark"
                autofocus
                @input="formSubmited = false"
                type="text"
                @keydown="disableSymbol($event)"
                v-model="form.value"
                placeholder="Փնտրել..."
                autocomplete="new-search"
                class="w-full text-uppercase"
                old-left-icon-name="search">
            </MazInput>
          </b-col>
          <b-col md="6">
            <b-button @click="searchFmItem" :disabled="!form.value || submited" variant="primary" class="w-100 h-100">Որոնել</b-button>
          </b-col>
        </b-row>

        <b-row  v-if="fmResult">
          <div class="restoreForm">
            <div class="title">
              {{ fmResult.car }} {{ fmResult.car_model }}
            </div>
            <div class="days">
              <div class="day" :class="restoreDay === '2024-03-20' ? 'selected' : ''" @click="selectRestoreDay('2024-03-20')">20</div>
              <div class="day" :class="restoreDay === '2024-03-21' ? 'selected' : ''" @click="selectRestoreDay('2024-03-21')">21</div>
              <div class="day" :class="restoreDay === '2024-03-22' ? 'selected' : ''" @click="selectRestoreDay('2024-03-22')">22</div>
              <div class="day" :class="restoreDay === '2024-03-23' ? 'selected' : ''" @click="selectRestoreDay('2024-03-23')">23</div>
              <div class="day" :class="restoreDay === '2024-03-24' ? 'selected' : ''" @click="selectRestoreDay('2024-03-24')">24</div>
              <div class="day" :class="restoreDay === '2024-03-25' ? 'selected' : ''" @click="selectRestoreDay('2024-03-25')">25</div>
              <div class="day" :class="restoreDay === '2024-03-26' ? 'selected' : ''" @click="selectRestoreDay('2024-03-26')">26</div>
              <div class="day" :class="restoreDay === '2024-03-27' ? 'selected' : ''" @click="selectRestoreDay('2024-03-27')">27</div>
              <div class="day" :class="restoreDay === '2024-03-28' ? 'selected' : ''" @click="selectRestoreDay('2024-03-28')">28</div>
            </div>
            <div class="actions">
              <b-button variant="primary" :disabled="!restoreDay || submited" @click="restoreFm">Պահպանել</b-button>
            </div>
          </div>
        </b-row>

      </div>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import AG_GRID_LOCALE_HY from '@core/global/ag-grid-hy'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { length, regex, required } from '@validations'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BAlert,
  BFormTextarea,
  BCardText,
  VBTooltip
} from 'bootstrap-vue'
import Form from '@/views/pages/tech-service/Form'
import Classificator from '@/views/pages/tech-service/docs/Classificator'
import Financial from '@/views/pages/tech-service/docs/Financial'
import Fuel from '@/views/pages/tech-service/docs/Fuel'
import Count from '@/views/pages/tech-service/docs/Count'
import Info from '@/views/pages/tech-service/docs/Info'
import Individual from '@/views/pages/tech-service/docs/Individual'
import Entrepreneur from '@/views/pages/tech-service/docs/Entrepreneur'
import Company from '@/views/pages/tech-service/docs/Company'
import Amounts from '@/views/pages/tech-service/docs/Amounts'
import Operators from '@/views/pages/tech-service/docs/Operators'
import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'
// Cells
import CellRendererOpinion from '@/views/pages/tech-service/columns/CellRendererOpinion'
import CellRendererChanges from '@/views/pages/tech-service/columns/CellRendererChanges'
import CellRendererCanEdit from '@/views/pages/tech-service/columns/CellRendererCanEdit'
import CellRendererStatus from '@/views/pages/tech-service/columns/CellRendererStatus'
import CellRendererEdit from '@/views/pages/tech-service/columns/CellRendererEdit'
import CellRendererAttachment from '@/views/pages/tech-service/columns/CellRendererAttachment'
import Progress from 'vue-multiple-progress'
Vue.use(Progress)
const uniqid = require('uniqid')
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import carsModule from '@/store/cars/cars'
import locationsModule from '@/store/locations/locations'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
  ref: 'techServiceOperator',
  name: 'tech-service',
  components: {
    vSelect,
    BCardCode,
    AgGridVue,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BTable,
    BAlert,
    BFormTextarea,
    BCardText,
    Form,
    Classificator,
    Financial,
    Fuel,
    Count,
    Info,
    Individual,
    Company,
    Entrepreneur,
    Amounts,
    Operators,
    CellRendererOpinion,
    CellRendererChanges,
    CellRendererCanEdit,
    CellRendererEdit,
    CellRendererAttachment,
    DateRangePicker,
    ValidationProvider,
    ValidationObserver,
    CellRendererStatus
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {

    fuels() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].fuels.map(e => ({
        label: e.name,
        value: e.id
      }))
    },

    car_groups() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_groups
    },

    car_types() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_types
    },

    totalPayments() {
      return payments => {
        let amount = 0;
        for (let key in payments) {
          let items = payments[key].info
          items.map(item => {
            amount += item.amount
          })
        }
        return amount
      }
    },

    totalPaymentItem() {
      return payments => {
        let amount = 0;
        payments.map(item => {
          amount += item.amount
        })
        return amount
      }
    },

    nextCheckupDate() {
      return data => {
        const date = new Date(data.item.createdAt);
        const next = data.item.NextCheckup;
        if (next != 0) {
          date.setMonth(date.getMonth() + (next * 12));
        } else {
          date.setMonth(date.getMonth() + 6);
        }
        return date;
      }
    },

    tax_amount() {
      return (tax) => {
        let amount = 0;
        amount += tax.tax.debt
        // if (tax.penalty) {
        //   amount += tax.penalty.debt
        // }
        return amount
      }
    },

    checkupNumbers() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupNumbers
    },

    checkupHistory() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupHistory
    },

    company() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
    },

    companyID() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company._id
    },

    columnDefs() {
      return [
        {
          headerName: 'Կարգավիճակ',
          children: [
            {
              headerName: '#',
              field: 'createdAt',
              width: 150,
              cellRendererFramework: 'CellRendererEdit',
              hide: false
            },
            {
              headerName: 'Կարգավիճակ',
              field: 'createdAt',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'sent',
                className: '',
                options: [
                  {
                    value: false,
                    label: 'Ընթացիք'
                  },
                  {
                    value: true,
                    label: 'Հաստատված'
                  }
                ]
              },
              width: 180,
              cellRendererFramework: 'CellRendererCanEdit'
            },
            {
              headerName: 'Գույքահարկ',
              field: 'inspectionStatus',
              filter: true,
              width: 180,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              headerName: 'ԴԱՀԿ',
              field: 'dahk_exists',
              filter: true,
              width: 150,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              headerName: 'Ստեղծվել է',
              field: 'createdAt',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'createdAt',
                className: ''
              },
              width: 150,
              valueFormatter: this.dateFormatter
            },
            {
              headerName: '📁',
              field: 'attachment',
              filter: false,
              width: 30,
              hide: !this.company || !this.company.attachment,
              cellRendererFramework: 'CellRendererAttachment',
            },
            {
              headerName: '📷',
              field: 'camera',
              filter: false,
              width: 30,
              hide: !this.company || !this.company.camera,
              cellRendererFramework: 'CellRendererAttachment',
            },
            {
              headerName: 'Օպերատոր',
              field: 'createdBy.name',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'createdBy',
                className: '',
                options: this.company_operators
              },
              width: 150,
            },
          ]
        },
        {
          headerName: 'Տեխզննում',
          children: [
            {
              headerName: 'Մեքենա',
              field: 'ownerStatus.Owner',
              width: 180,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              valueFormatter: this.carFormatter
            },
            {
              headerName: 'Համարանիշ',
              field: 'CarRegNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CarRegNo',
                className: 'text-uppercase'
              },
              width: 150,
              valueFormatter: this.carRegNoFormatter
            },
            {
              headerName: 'Փաստաթուղթ',
              field: 'documentNumber',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'documentNumber',
                className: 'text-uppercase'
              },
              width: 140,
              valueFormatter: this.uppercaseFormatter
            },
            {
              headerName: 'Շարժիչ',
              field: 'engine',
              filter: false,
              width: 140,
            },
            {
              headerName: 'Նույնացման (VIN) համարը',
              field: 'VIN',
              filter: false,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'VIN',
                className: 'text-uppercase'
              },
              width: 210,
            },
            {
              headerName: 'Հաջորդ Տ․Զ․',
              field: 'NextCheckup',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'NextCheckup',
                className: '',
                options: [
                  {
                    value: 0,
                    label: '6 ամսից'
                  },
                  {
                    value: 1,
                    label: '1 տարուց'
                  },
                  {
                    value: 2,
                    label: '2 տարուց'
                  }
                ]
              },
              width: 160,
              valueFormatter: this.nextCheckupFormatter
            },
            // {
            //   headerName: 'Թափքի տեսակ',
            //   field: 'body',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'body',
            //     className: ''
            //   },
            //   width: 150,
            //   valueFormatter: this.bodyNameFormatter,
            // },
            // {
            //   headerName: 'Գույն',
            //   field: 'color',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'color',
            //     className: ''
            //   },
            //   width: 180,
            // }
          ]
        },
        {
          headerName: 'Տրանսպորտային միջոցի տնօրինող',
          children: [
            {
              headerName: 'Տնօրինող',
              field: 'ownerStatus.Owner',
              width: 200,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ownerStatus.Owner',
                className: ''
              },
            },
            {
              headerName: 'Հեռախոս',
              field: 'Phone',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Phone',
                className: ''
              },
              width: 130,
            },
            {
              headerName: 'Մարզ',
              field: 'region',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'region',
                className: ''
              },
              width: 180,
            },
            {
              headerName: 'Քաղաք',
              field: 'county',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'county',
                className: ''
              },
              width: 180,
            },
            {
              headerName: 'Փողոց',
              field: 'street',
              filter: 180,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'street',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Հասցե',
              field: 'address',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'address',
                className: ''
              },
              width: 150,
            },
            {
              headerName: 'Գրանցման փաստաթուղթ',
              field: 'documentNumber',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'documentNumber',
                className: 'text-uppercase'
              },
              width: 210,
              valueFormatter: this.uppercaseFormatter
            },
          ]
        },
        {
          headerName: 'Տրանսպորտային միջոց',
          children: [
            {
              headerName: 'Խումբ',
              field: 'car_group',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_group',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Տեսակ',
              field: 'car_type',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_type',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Մեքենա',
              field: 'car',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              width: 210,
              valueFormatter: this.carFormatter
            },
            {
              headerName: 'Մակնիշ',
              field: 'car',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Մոդել',
              field: 'car_model',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_model',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Թողարկման տարեթիվ',
              field: 'MadeAt',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'MadeAt',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Վազք',
              field: 'vazq',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'vazq',
                className: ''
              },
              width: 210,
              hide: !this.company || !this.company.vazq,
              valueFormatter: this.vazqFormatter
            },
            {
              headerName: 'Գրանցման համարանիշ',
              field: 'CarRegNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CarRegNo',
                className: 'text-uppercase'
              },
              width: 210,
              valueFormatter: this.carRegNoFormatter
            },
            {
              headerName: 'Վառելիքի տեսակ',
              field: 'fuel',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'fuel',
                className: ''
              },
              width: 210,
            },
            // {
            //   headerName: 'Թափքի տեսակ',
            //   field: 'body',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'body',
            //     className: ''
            //   },
            //   width: 210,
            //   valueFormatter: this.bodyNameFormatter
            // },
            // {
            //   headerName: 'Գույն',
            //   field: 'color',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'color',
            //     className: ''
            //   },
            //   width: 210,
            // },
            {
              headerName: 'Նույնացման (VIN) համարը',
              field: 'VIN',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'VIN',
                className: ''
              },
              width: 210,
            },
            // {
            //   headerName: 'Շարժիչի համար',
            //   field: 'EngineNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'EngineNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   headerName: 'Հենասարքի համար',
            //   field: 'TrilerNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'TrilerNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   headerName: 'Թափքի համար',
            //   field: 'BodyNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'BodyNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   headerName: 'Գազ․ վեր․ ակտ N',
            //   field: 'GasNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'GasNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            {
              headerName: 'Տրված է',
              field: 'GasDate',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'GasDate',
                className: ''
              },
              width: 210,
              valueFormatter: this.dayFormatter
            },
          ]
        },
        {
          headerName: 'Կառուցվածքային փոփոխություն',
          children: [
            {
              headerName: 'Առկա է',
              field: 'Changes',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Changes',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Առկա է'
                  },
                  {
                    value: false,
                    label: 'Առկա չէ'
                  }
                ]
              },
              width: 115,
              cellRendererFramework: 'CellRendererChanges'
            },
            {
              headerName: 'Փոփոխություն',
              field: 'ChangesNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ChangesNotes',
                className: ''
              },
              width: 210,
            },
          ]
        },
        {
          headerName: 'Տեխնիկական զննություն',
          children: [
            {
              headerName: 'Կտրոնի համար',
              field: 'CheckNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CheckNo',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Նշումներ',
              field: 'CheckNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CheckNotes',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'ՏԶ կտրոնի տրման ամսաթիվ',
              field: 'EkoDate',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'EkoDate',
                className: ''
              },
              width: 210,
              valueFormatter: this.dayFormatter
            },
            {
              headerName: 'Եզրակացություն',
              field: 'Opinion',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Opinion',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Սարքին'
                  },
                  {
                    value: false,
                    label: 'Անսարք'
                  }
                ]
              },
              width: 180,
              cellRendererFramework: 'CellRendererOpinion'
            },
            {
              headerName: 'Պատճառը',
              field: 'OpinionNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'OpinionNotes',
                className: ''
              },
              width: 210,
            },
            {
              headerName: 'Հաջորդ Տ․Զ․',
              field: 'NextCheckup',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'NextCheckup',
                className: '',
                options: [
                  {
                    value: 0,
                    label: '6 ամսից'
                  },
                  {
                    value: 1,
                    label: '1 տարուց'
                  },
                  {
                    value: 2,
                    label: '2 տարուց'
                  }
                ]
              },
              width: 160,
              valueFormatter: this.nextCheckupFormatter
            },
            {
              headerName: 'Կրկնակի Տ․Զ․',
              field: 'DoubleChecked',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'DoubleChecked',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Այո'
                  },
                  {
                    value: false,
                    label: 'Ոչ'
                  },
                ]
              },
              width: 140,
              cellRendererFramework: 'CellRendererChanges'
            },
          ]
        },
        // {
        //   headerName: 'Վճարումներ',
        //   children: [
        //     // {
        //     //   headerName: 'Բն․ անդ․ N',
        //     //   field: 'EkoNo',
        //     //   filter: true,
        //     //   floatingFilterComponent: 'customFloatingFilter',
        //     //   floatingFilterComponentParams: {
        //     //     suppressFilterButton: true,
        //     //     field: 'EkoNo',
        //     //     className: ''
        //     //   },
        //     //   width: 210,
        //     // },
        //     {
        //       headerName: 'Գումար',
        //       field: 'EkoSum',
        //       filter: true,
        //       floatingFilterComponent: 'customFloatingFilter',
        //       floatingFilterComponentParams: {
        //         suppressFilterButton: true,
        //         field: 'EkoSum',
        //         className: ''
        //       },
        //       width: 210,
        //     },
        //     {
        //       headerName: 'Տ․Զ․ վճար',
        //       field: 'Sum',
        //       filter: true,
        //       floatingFilterComponent: 'customFloatingFilter',
        //       floatingFilterComponentParams: {
        //         suppressFilterButton: true,
        //         field: 'Sum',
        //         className: ''
        //       },
        //       width: 210,
        //     }
        //   ]
        // }
      ]
    },

    company_operators() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company_operators.map(e => ({
        label: e.name,
        value: e._id,
      }))
    },

    edit_confirmed_title() {
      return (this.askToEditItem) ? this.askToEditItem.CarRegNo + '' : ''
    },

    bodies() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].bodies
    },

    add() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].add
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    docs() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].docs
    },

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkups
    },

    search_result() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].search_result
    },

    date_search_result() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].date_search_result
    },

    search_payment_result() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].search_payment_result
    },

    pagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    filters: {
      get() {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
      },
      set(data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_INITIAL_FILTERS', data)
      }
    },

    ownerStatuses() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].ownerStatuses
    },

    nextCheckups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].nextCheckups
    },

    table_class() {
      return (this.isDark) ? 'tech-service-table ag-theme-alpine-dark w-100 ag-grid-table' : 'tech-service-table ag-theme-alpine w-100 ag-grid-table'
    },

    localization() {
      return AG_GRID_LOCALE_HY
    },
    search_filters_top() {
      return [
        {
          label: 'Գրանցման համարանիշի',
          id: 'CarRegNo'
        },
        {
          label: 'Գրանցման փաստաթղթի',
          id: 'documentNumber'
        },
        {
          label: 'Նույնականացման VIN կոդի',
          id: 'VIN'
        },
        // {
        //   label: 'Շարժիչի համարի',
        //   value: 'EngineNo'
        // },
        // {
        //   label: 'Հենասարքի համարի',
        //   value: 'TrilerNo'
        // },
        // {
        //   label: 'Թափքի համարի',
        //   value: 'BodyNo'
        // }
      ]
    },
    search_filters() {
      return [
        {
          label: 'Գրանցման համարանիշի',
          value: 'CarRegNo'
        },
        {
          label: 'Կտրոնի համարի',
          value: 'CheckNo'
        },
        {
          label: 'Նույնականացման VIN կոդի',
          value: 'VIN'
        },
        // {
        //   label: 'Շարժիչի համարի',
        //   value: 'EngineNo'
        // },
        // {
        //   label: 'Հենասարքի համարի',
        //   value: 'TrilerNo'
        // },
        // {
        //   label: 'Թափքի համարի',
        //   value: 'BodyNo'
        // }
      ]
    },

    temporary_id() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].temporary_id
    },
    gridApi() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].gridApi
    },
    gridColumnApi() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].gridColumnApi
    },
    dateRange: {
      get() {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].dateRange
      },
      set(value) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', value)
      }
    },
    dateRanges() {
      // Today
      let today = new Date()

      let todayStart = new Date()
      todayStart.setHours(0, 0, 0, 0)

      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 59)

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 59)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let thisQuarterStart = new Date(today.getFullYear(), today.getMonth() - 2, 1)
      let thisQuarterEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Quarter
      let lastQuarterStart = new Date(today.getFullYear(), today.getMonth() - 5, 1)
      let lastQuarterEnd = new Date(today.getFullYear(), today.getMonth() - 2, 0)

      // This Semester
      let thisSemesterStart = new Date(today.getFullYear(), today.getMonth() - 5, 1)
      let thisSemesterEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Semester
      let lastSemesterStart = new Date(today.getFullYear(), today.getMonth() - 11, 1)
      let lastSemesterEnd = new Date(today.getFullYear(), today.getMonth() - 5, 0)

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      return {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': []
      }
    },

    askToEditItem() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].askToEditItem
    },
  },
  data() {
    return {
      searchResult: null,
      payments: null,
      vehicleTypes: [],
      vehicleType: null,
      taxesInfo: [],
      selectedService: null,
      selectedPayment: null,
      required,
      regex,
      length,
      selectedCheckupId: null,
      selectedCheckup: null,
      selectedCheckupNumbers: [],
      newCheckupNumber: null,
      notification: {
        content: ''
      },
      formSubmited: false,
      dateFormSubmited: false,
      showCalendar: false,
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
      },
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      currentPage: 1,
      // Filter Options
      roleFilter: {
        label: 'All',
        value: 'all'
      },
      roleOptions: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Admin',
          value: 'admin'
        },
        {
          label: 'User',
          value: 'user'
        },
        {
          label: 'Staff',
          value: 'staff'
        }
      ],
      statusFilter: {
        label: 'All',
        value: 'all'
      },
      statusOptions: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Active',
          value: 'active'
        },
        {
          label: 'Deactivated',
          value: 'deactivated'
        },
        {
          label: 'Blocked',
          value: 'blocked'
        }
      ],
      isVerifiedFilter: {
        label: 'All',
        value: 'all'
      },
      isVerifiedOptions: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Yes',
          value: 'yes'
        },
        {
          label: 'No',
          value: 'no'
        }
      ],
      departmentFilter: {
        label: 'All',
        value: 'all'
      },
      departmentOptions: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Sales',
          value: 'sales'
        },
        {
          label: 'Development',
          value: 'development'
        },
        {
          label: 'Management',
          value: 'management'
        }
      ],
      searchQuery: '',
      submited: false,
      errorMessage: null,
      in_progress_text: '',
      progress_percentage: 100,

      // AgGrid
      gridOptions: {
        getRowStyle: (params) => {
          if (params.data.CheckNoEdited) {
            return { 'background-color': '#ffc107' }
          }
          if (params.data.deleted) {
            return { 'background-color': '#afafaf' }
          }
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },

      // Cell Renderer Components
      components: {},
      form: {
        filter: 'CarRegNo',
        value: ''
      },
      rsForm: {
        region: '',
        region_id: null,
        county: '',
        county_id: null,
        street: '',
        street_id: null,
        address: '',
        addressName: '',
        ownerName: '',
        ownerStatus: {
          Owner: '',
          Status: 0,
          Notes: ''
        },
        Phone: '',
        documentNumber: '',
        car_group: '',
        car_type_id: null,
        car_type: '',
        car_id: null,
        car: '',
        car_model_id: null,
        car_model: '',
        CarRegNo: '',
        YellowCarRegNo: '',
        DefaultCarRegNo: '',
        body: '',
        body_id: null,
        color: '',
        color_id: null,
        fuel: '',
        fuel_id: null,
        MadeAt: '',
        VIN: '',
        EngineNo: '',
        TrilerNo: '',
        GasNo: '',
        GasDate: '',
        Changes: '0',
        ChangesNotes: '',
        Opinion: '1',
        OpinionNotes: '',
        CheckNo: '',
        NextCheckup: 1,
        Sum: '',
        EkoNo: '',
        EkoDate: null,
        EkoSum: '',
        classificators: [],
        engine: null,
        dahk_exists: false,
        reg_num: null,
        vazqType: 1,
        vazq: null,
        attachment: null
      },
      fmResult: null,
      restoreDay: null,
      DateFormSearch: null,
      fields: [
        {
          key: 'action',
          label: '',
          class: 'width-10-per px-1'
        },
        {
          key: 'validDate',
          label: 'Ամսաթիվ',
        },
        {
          key: 'payment',
          label: 'Վճարումներ',
        },
        {
          key: 'car_model',
          label: 'Մեքենա',
        },
        {
          key: 'pays',
          label: '',
        },
        {
          key: 'taxes',
          label: 'Գույքահարկ',
        },
        {
          key: 'dahk_exists',
          label: 'ԴԱՀԿ',
        },
        {
          key: 'CarRegNo',
          label: 'Գր․ համարանիշ',
        },
        {
          key: 'VIN',
          label: 'VIN',
        }
      ],
      dateFields: [
        {
          key: 'action',
          label: '',
          class: 'width-10-per px-1'
        },
        {
          key: 'createdAt',
          label: 'Սկիզբ',
        },
        {
          key: 'createdAt1',
          label: 'Ավարտ',
        },
        {
          key: 'taxes',
          label: 'Գույքահարկ',
        },
        {
          key: 'payment',
          label: 'Վճարումներ',
        },
        {
          key: 'car_model',
          label: 'Մեքենա',
        },
        {
          key: 'documentNumber',
          label: 'Փաստաթղթի համար',
        },
        {
          key: 'CarRegNo',
          label: 'Գր․ համարանիշ',
        },
        {
          key: 'VIN',
          label: 'VIN',
        }
      ],
      fields_payments: [
        {
          key: 'action',
          label: '',
          class: 'width-10-per px-1'
        },
        {
          key: 'created_at',
          label: 'Ամսաթիվ',
        },
        {
          key: 'payment',
          label: 'Վճարումներ',
        },
        {
          key: 'car_model',
          label: 'Մեքենա',
        },
        {
          key: 'MadeAt',
          label: 'Թող․ տարեթիվ',
        },
        {
          key: 'CarRegNo',
          label: 'Գր․ համարանիշ',
        }
      ],
    }
  },

  beforeMount() {
    this.components = {
      customFloatingFilter: this.getCustomFloatingFilterComponent(),
      customDateFloatingFilter: this.getCustomDateFloatingFilterComponent(),
      customSelectFloatingFilter: this.getCustomSelectFloatingFilterComponent(),
    }
  },

  mounted() {
    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_API', this.gridOptions.api)
    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_COLUMN_API', this.gridOptions.columnApi)
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    let todayStart = new Date()
    todayStart.setHours(0, 0, 0, 0)

    let todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 59)

    const initialFilters = {
      Opinion: true,
      startDate: todayStart,
      endDate: todayEnd,
      search: '',
      company: '',
      fields: {}
    }

    return {
      skin,
      isDark,
      initialFilters
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locationsModule)
    this.getCheckups()
    this.getOperators()
    this.getCompany()
    this.getCarGroups()
    this.getCarTypes()
    this.getCars()
    this.getBodies()
    this.getColors()
    this.getFuels()
    this.getClassificators()
    this.getRegions()
    this.getTodayCheckupsCount()
    this.checkNotifications()
    this.filters = this.initialFilters
    setTimeout(() => {
      if (this.company.alert) {
        document.body.classList.add('bg-blocked');
        // this.$swal({
        //   title: 'Հիշեցում',
        //   text: ``,
        //   imageUrl: require('@/assets/images/icons/alert.png'),
        //   customClass: {
        //     confirmButton: 'btn btn-primary',
        //   },
        //   buttonsStyling: false,
        // })
      }
    }, 500)
  },

  destroyed() {
    this.filters = this.initialFilters
  },

  methods: {

    setSelectedType(type) {
      let result = [...this.search_result];
      result[0].vehicleType = type.id;
      this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/SET_SEARCH_RESULT`, result);
    },

    clearSearchResult() {
      this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/SET_SEARCH_RESULT`, []);
    },

    closeMobileModal() {
      this.$bvModal.hide('modal-search-mobile')
    },

    openTaxesInfo(taxes) {
      this.taxesInfo = taxes
      this.$bvModal.show('modal-taxes-info')
    },

    openPaymentsInfo(info) {
      this.searchResult = info
      this.payments = info.payments
      this.vehicleTypes = info.vehicleTypes
      this.$bvModal.show('modal-payment-info')
    },

    selectVehicleType (type) {
      this.vehicleType = type.id
      let data = this.form.filter + '=' + this.form.value
      if (this.vehicleType) {
        data += `&vehicleType=${this.vehicleType}`
      }
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/searchCheckups', data)
          .then(response => {
            this.$vs.loading.close()
            let info = response.data.data[0]
            this.searchResult = info
            this.payments = info.payments
          }).catch(() => {
            this.$vs.loading.close()
      })
    },

    printReceipt() {
      const print_window = window.open('', '', 'height=768,width=1024')

      print_window.document.write('<html>')
      print_window.document.write('<head>')
      print_window.document.write('<title></title>')
      print_window.document.write('<link rel="stylesheet" href="/assets/css/print.css" type="text/css"/>')
      print_window.document.write('</head>')
      print_window.document.write('<body>')
      print_window.document.write(document.getElementById('receipt-modal').innerHTML)
      print_window.document.write('</body>')
      print_window.document.write('</html>')
      setTimeout(() => {
        print_window.document.close()
        print_window.focus()
        print_window.print()
        print_window.close()
      }, 1000)
      return true
    },

    openReciept(order, service) {
      this.selectedService = service
      this.selectedPayment = order
      this.$bvModal.show('receipt-modal')
    },

    openClassificatorDoc (data) {
      this.$bvModal.hide('modal-search')
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SELECTED_CHECKUP', data)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'classificator')
    },

    getTodayCheckupsCount() {
      this.$store.dispatch('getTodayCheckupsCount')
      this.$store.dispatch('getTodayOfflineCheckupsCount')
    },

    checkNotifications() {
      setInterval(() => {
        this.$store.dispatch('getNotifications')
          .then(response => {
            let notifications = response.data.data;
            let showedNotifications = [];
            const checkNotifications = localStorage.getItem('notifications');
            if (checkNotifications) {
              showedNotifications = JSON.parse(checkNotifications);
            }
            notifications = notifications.filter(notification => !showedNotifications.includes(notification._id));
            if (notifications.length > 0) {
              let currentNotification = notifications[0];
              showedNotifications.push(currentNotification._id);
              localStorage.setItem('notifications', JSON.stringify(showedNotifications));
              this.$swal({
                title: currentNotification.title,
                text: currentNotification.message,
                icon: 'info',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
      }, 10000);
    },

    getOperators() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getOperators')
    },

    confirmAskToEdit() {
      const payload = {
        notificationType: 'ASK_TO_EDIT',
        title: this.askToEditItem.CarRegNo + ' - խմբագրման հայտ',
        message: this.notification.content,
        data: {
          id: this.askToEditItem._id,
          collection: 'checkups'
        },
        companyName: this.company.name
      }
      this.$store.dispatch('addNotification', payload)
          .then((response) => {
            this.$swal({
              title: '',
              text: 'Խմբագրման հայտն ուղարկվել է!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.notification.content = ''
            this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_ASK_TO_EDIT_ITEM', null)
            response.data.map((notification) => {
              // this.$socket.emit('SEND_NOTIFICATION', notification)
            })
          })
    },

    getCarGroups() {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getGroups')
    },

    getCarTypes() {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getTypes')
    },

    getCars() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getCars')
    },

    getBodies() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getBodies')
    },

    getColors() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getColors')
    },

    getFuels() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getFuels')
    },

    getClassificators() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getClassificators')
    },

    getRegions() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getRegions')
    },

    hideSearchModal() {
      this.form = {
        filter: 'CarRegNo',
        value: ''
      }
      this.dateFormSubmited = false
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_DATE_RESULT', [])
    },

    hideSearchPaymentModal() {
      this.form = {
        filter: 'CarRegNo',
        value: ''
      }
    },

    onGridReady(params) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_API', params.api)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_COLUMN_API', params.columnApi)
    },

    getCompany() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCompany');
    },

    getCheckups() {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckups', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getCheckups()
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    addNewService() {
      this.add = true
    },

    ownerStatusFormatter(params) {
      const index = this.ownerStatuses.findIndex((i) => i.id === params.value)
      if (index >= 0) {
        return this.ownerStatuses[index].title
      }
      return ''
    },

    carFormatter(params) {
      return params.data.car + ' ' + params.data.car_model
    },

    carRegNoFormatter(params) {
      return `${params.data.CarRegNo} ${(params.data.YellowCarRegNo) ? `(${params.data.YellowCarRegNo.toLocaleUpperCase()})` : ''}`
    },

    vazqFormatter(params) {
      return (params.data.vazq) ? `${params.data.vazq} ${params.data.vazqType === 1 ? 'կմ' : 'մղոն'}` : '-'
    },

    uppercaseFormatter(params) {
      return params.value.toLocaleUpperCase()
    },

    carNameFormatter(params) {
      let carName = (params.value && typeof params.value.car !== 'undefined' && params.value.car && typeof params.value.car.name !== 'undefined') ? params.value.car.name : ''
      return carName
    },

    bodyNameFormatter(params) {
      if (params.value) {
        return params.value
      } else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === params.data.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    },

    dateFormatter(params) {
      return this.$options.filters.formatDate(params.value)
    },

    dayFormatter(params) {
      return this.$options.filters.formatDay(params.value)
    },

    nextCheckupFormatter(params) {
      const index = this.nextCheckups.findIndex((e) => e.value == params.value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return params.value
    },
    searchCheckups() {
      this.errorMessage = null;
      this.submited = true
      this.in_progress_text = 'Կատարվում է հարցում'
      setTimeout(() => {
        this.in_progress_text = 'Փոխադրամիջոցի գույքահարկ'
      }, 10000)
      setTimeout(() => {
        this.in_progress_text = 'ԴԱՀԿ ստուգում'
      }, 15000)
      if (this.form.value !== '') {
        let data = this.form.filter + '=' + this.form.value
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/searchCheckups', data)
            .then(() => {
              this.errorMessage = null;
              this.submited = false
              this.formSubmited = true
            })
            .catch(error => {
              if (error.response.data.statusCode == 500) {
                this.errorMessage = error.response.data.message;
              } else {
                this.errorMessage = 'Տվյալներ չեն գտնվել:';
              }
              this.submited = false
            })
      } else {
        this.submited = false
        this.$vs.loading.close()
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_RESULT', [])
      }
    },
    searchFmItem() {
      this.submited = true
      if (this.form.value !== '') {
        let data = this.form.filter + '=' + this.form.value
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/searchCheckups', data)
            .then(response => {
              if (response.data.data.length > 0) {
                this.fmResult = response.data.data[0]
              }
              this.submited = false
              this.formSubmited = true
            })
            .catch(() => {
              this.submited = false
            })
      } else {
        this.submited = false
        this.$vs.loading.close()
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_RESULT', [])
      }
    },
    restoreFm() {
      const data = this.fmResult;
      if (data.vehicleType) {
        let car_group_name
        switch (data.vehicleType) {
          case 'passenger':
            car_group_name = 'Թեթև մարդատար';
            break;
          case 'minibus':
          case 'bus':
            car_group_name = 'Ավտոբուս';
            break;
          case 'truck_1':
          case 'truck_2':
          case 'truck_3':
            car_group_name = 'Բեռնատար կողավոր և մասնագիտացված';
            break;
          case 'motorcycle':
            car_group_name = 'Մոտոցիկլ';
            break;
          case 'attachment':
            car_group_name = 'Կցորդներ';
            break;
        }
        let car_group = this.car_groups.find(group => group.name === car_group_name);

        if (car_group) {
          data.car_group_id = car_group.id;
          data.car_group = car_group.name;
        }

        let vehicleTypes = this.getVehicleTypesArray();
        let vehicleType = vehicleTypes.find(type => type.id === data.vehicleType)
        let vehicle_type_name = vehicleType.name
        let vehicleTypeName
        switch (vehicle_type_name) {
          case 'Թեթև մարդատար':
            vehicleTypeName = 'Թեթև մարդատար';
            break;
          case 'Միկրոավտոբուս':
            vehicleTypeName = 'Միկրոավտոբուս';
            break;
          case 'Ավտոբուս':
            vehicleTypeName = 'Ավտոբուս';
            break;
          case 'Բեռնատար(<3.5տ.)':
            vehicleTypeName = 'Մինչև 3.5 տ. լրիվ զանգվածով';
            break;
          case 'Բեռնատար(3.5-12տ.)':
            vehicleTypeName = '3.5-12տ. լրիվ զանգվածով';
            break;
          case 'Բեռնատար(>12տ.)':
            vehicleTypeName = '12տ. -ից ավել լրիվ զանգվածով';
            break;
          case 'Մոտոցիկլետ':
            vehicleTypeName = 'Մոտոցիկլետ';
            break;
          case 'Կցորդ':
            vehicleTypeName = 'Կցորդներ';
            break;
        }
        let car_type = this.car_types.find(type => type.name === vehicleTypeName)
        if (car_type) {
          data.car_type_id = car_type.id;
          data.car_type = car_type.name;
        }

      }

      let fuel = this.fuels.find(f => f.label === 'Բենզին');
      if (fuel) {
        data.fuel_id = fuel.value;
        data.fuel = fuel.label;
      }

      this.rsForm = {
        region: data.region,
        county: data.county,
        street: data.street,
        address: data.address,
        addressName: data.region + ', ' + data.county + ', ' + data.street + ', ' + data.address,
        ownerName: data.ownerStatus.Owner,
        ownerStatus: data.ownerStatus,
        Phone: data.mobile,
        documentNumber: data.documentNumber,
        car_group_id: data.car_group_id,
        car_group: data.car_group,
        car_type: data.car_type,
        car_type_id: data.car_type_id,
        car: data.car,
        car_id: data.car_id,
        car_model: data.car_model,
        car_model_id: data.car_model_id,
        CarRegNo: data.CarRegNo,
        YellowCarRegNo: data.YellowCarRegNo,
        body: data.body,
        body_id: data.body_id,
        color: data.color,
        color_id: data.color_id,
        fuel: data.fuel,
        fuel_id: data.fuel_id,
        MadeAt: data.MadeAt,
        VIN: data.VIN,
        EngineNo: data.EngineNo,
        TrilerNo: data.TrilerNo,
        BodyNo: data.BodyNo,
        GasNo: '',
        GasDate: null,
        Changes: false,
        ChangesNotes: '',
        Opinion: true,
        OpinionNotes: '',
        NextCheckup: '',
        Sum: data.Sum,
        EkoNo: '',
        EkoDate: null,
        EkoSum: '',
        classificators: [],
        vehicle_type: data.vehicle_type,
        special_notes: data.special_notes,
        weight: data.weight,
        max_weight: data.max_weight,
        fuel_name: data.fuel_name,
        identification_no: data.identification_no,
        psn: data.psn,
        mobile: data.mobile,
        inspectionStatus: data.inspectionStatus,
        engine: data.engine,
        payment_id: (data.payment) ? data.payment.id : null,
        copy: data.copy,
        dahk_exists: data.dahk_exists,
        reg_num: data.reg_num,
        vazqType: (data.vazqType) ? data.vazqType : 1,
        vazq: data.vazq,
        insuranceStartDate: data.insuranceStartDate,
        insuranceEndDate: data.insuranceEndDate,
        insuranceCompany: data.insuranceCompany,
        dahkInfo: data.dahkInfo,
        manuallyRestored: true
      }
      this.changeYear();
      this.calculateEkoSum()
      this.changeCarType(this.rsForm.car_type_id)
      this.$vs.loading()
      let payload = JSON.parse(JSON.stringify(this.rsForm))
      payload.CarRegNo = this.rsForm.CarRegNo.toUpperCase()
      payload.VIN = this.rsForm.VIN.toUpperCase()
      payload.ownerStatus.Owner = this.toProperCase(this.rsForm.ownerStatus.Owner)
      payload.documentNumber = this.rsForm.documentNumber.toUpperCase()
      let parts = this.restoreDay.split('-');
      let year = parseInt(parts[0]);
      let month = parseInt(parts[1]) - 1;
      let day = parseInt(parts[2]);


      let currentDate = new Date();
      let newDate = new Date(year, month, day, currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());

      payload.createdAt = newDate;
      payload.updatedAt = newDate;
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/addCheckupRestored', payload)
          .then((response) => {
            this.restoreDay = null;
            this.submited = false;
            this.fmResult = null;
            this.form.value = '';
            this.$vs.loading.close()
            this.$bvModal.hide('fm-modal')
          })
          .catch((error) => {
            this.submited = false;
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },
    toProperCase(s) {
      s = s.toLocaleLowerCase()
      return s.replace(/([^\s:\-])([^\s:\-]*)/g, function ($0, $1, $2) {
        return $1.toUpperCase() + $2.toLowerCase()
      })
    },
    getVehicleTypesArray() {
      const types = {
        'passenger': {
          'name': 'Թեթև մարդատար'
        },
        'minibus': {
          'name': 'Միկրոավտոբուս'
        },
        'bus': {
          'name': 'Ավտոբուս'
        },
        'truck_1': {
          'name': 'Բեռնատար(<3.5տ.)'
        },
        'truck_2': {
          'name': 'Բեռնատար(3.5-12տ.)'
        },
        'truck_3': {
          'name': 'Բեռնատար(>12տ.)'
        },
        'motorcycle': {
          'name': 'Մոտոցիկլետ'
        },
        'attachment': {
          'name': 'Կցորդ'
        }
      };
      const response = [];

      for (const id in types) {
        if (types.hasOwnProperty(id)) {
          const type = types[id];
          type.id = id;
          response.push(type);
        }
      }

      return response;
    },
    changeCarType(id) {
      let index = this.car_types.findIndex((e) => e.id === id)
      if (index < 0) {
        index = 0
      }
      if (index >= 0) {
        this.rsForm.car_type = this.car_types[index].name
        this.rsForm.Sum = this.car_types[index].amount
        const group_index = this.car_groups.findIndex((g) => g.id === this.car_types[index].group)
        if (group_index >= 0) {
          this.rsForm.car_group = this.car_groups[group_index].name
        }
        this.changeYear()
      }
      this.calculateEkoSum()
    },
    calculateEkoSum() {
      // return false;
      let number = 1
      if (this.rsForm.NextCheckup === 2) {
        number = 2
      }
      this.ekoStatus = true
      this.rsForm.EkoSum = null
      if (this.rsForm.car_type === 'Թեթև մարդատար' || this.rsForm.car_type === 'Միկրոավտոբուս' || this.rsForm.car_type === 'Ավտոբուս') {
        const year = parseInt(this.rsForm.MadeAt, 10)
        const power = this.rsForm.engine

        const currentYear = new Date().getFullYear()

        const koes = {
          0: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 2.5
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 5
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 7.5
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 10
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 12.5
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 15
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 17.5
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 25
            }
          },
          1: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 2.5
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 5
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 7.5
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 10
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 12.5
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 15
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 17.5
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 25
            }
          },
          2: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 2.5
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 5
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 7.5
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 10
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 12.5
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 15
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 17.5
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 25
            }
          },
          3: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 3
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 6
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 9
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 12
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 15
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 18
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 21
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 30
            }
          },
          4: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 3.5
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 7
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 10.5
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 14
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 17.5
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 21
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 24.5
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 35
            }
          },
          5: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 4
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 8
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 12
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 16
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 20
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 24
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 28
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 40
            }
          },
          6: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 4.5
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 9
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 13.5
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 18
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 22.5
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 27
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 31.5
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 45
            }
          },
          7: {
            0: {
              'min': 0,
              'max': 50,
              'koef': 5
            },
            1: {
              'min': 51,
              'max': 80,
              'koef': 10
            },
            2: {
              'min': 81,
              'max': 100,
              'koef': 15
            },
            3: {
              'min': 101,
              'max': 150,
              'koef': 20
            },
            4: {
              'min': 151,
              'max': 200,
              'koef': 25
            },
            5: {
              'min': 201,
              'max': 250,
              'koef': 30
            },
            6: {
              'min': 251,
              'max': 300,
              'koef': 35
            },
            7: {
              'min': 301,
              'max': 100000,
              'koef': 50
            }
          }
        }

        const year_diff = currentYear - year + 1;
        let ranges
        if ((year_diff <= 8)) {
          ranges = koes[year_diff - 1]
        } else {
          ranges = koes[7]
        }
        let koef
        for (const range in ranges) {
          if (power >= ranges[range]['min'] && power <= ranges[range]['max']) {
            koef = ranges[range]['koef'];
            break
          }
        }
        if (typeof koef === "undefined") {
          const rangesData = Object.values(ranges)
          if (power > rangesData[rangesData.length - 1]['max']) {
            koef = rangesData[rangesData.length - 1]['koef']
          }
        }
        this.rsForm.EkoSum = koef * power * number
      } else if (this.rsForm.car_type == 'Մինչև 3.5 տ. լրիվ զանգվածով') {
        this.rsForm.EkoSum = 5000 * number
      } else if (this.rsForm.car_type == '3.5-12տ. լրիվ զանգվածով') {
        this.rsForm.EkoSum = 10000 * number
      } else if (this.rsForm.car_type == '12տ. -ից ավել լրիվ զանգվածով') {
        this.rsForm.EkoSum = 15000 * number
      } else if (this.rsForm.car_type == 'Կիսակցորդներ' || this.rsForm.car_type == 'Կցորդներ') {
        this.rsForm.EkoSum = null
        this.ekoStatus = false
      }

    },
    changeYear() {
      const currentYear = new Date().getFullYear()
      let year = parseInt(this.rsForm.MadeAt, 10)
      const difference = currentYear - year
      if (difference < 0) {
        this.rsForm.NextCheckup = 2
      }
      if (difference >= 3 && difference < 10) {
        this.rsForm.NextCheckup = 2
      } else if (difference >= 10) {
        this.rsForm.NextCheckup = 1
      }

      if (this.rsForm.car_type === 'Միկրոավտոբուս' || this.rsForm.car_type === 'Ավտոբուս') {
        if (difference >= 3 && difference < 10) {
          // this.nextCheckupDisabled = true
          this.rsForm.NextCheckup = 1
        } else if (difference >= 10) {
          // this.nextCheckupDisabled = true
          this.rsForm.NextCheckup = '0'
        }
      }

    },
    selectRestoreDay(day) {
      this.restoreDay = day;
    },
    findCheckupDate() {
      if (this.DateFormSearch !== '') {
        const data = `CarRegNo=${this.DateFormSearch}`
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/searchCheckupsDate', data)
            .then(() => {
              this.dateFormSubmited = true
            })
      } else {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_DATE_RESULT', [])
      }
    },
    searchPaymentCheckups() {
      if (this.form.value !== '') {
        const data = this.form.filter + '=' + this.form.value
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/searchPaymentCheckups', data)
            .then(() => {
              this.formSubmited = true
            })
      } else {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_PAYMENT_RESULT', null)
      }
    },
    copyCheckup(checkup, copy = false) {
      checkup.copy = copy;
      const is3Number = /^([0-9]{3}[a-zA-Z]{2}[0-9]{2})$/.test(checkup.CarRegNo)

      if (!checkup.dahk_status) {
        this.$swal({
          title: '',
          text: `${checkup.CarRegNo} համարանիշի տրանսպորտային միջոցը գտնվում է ԴԱՀԿ հետախուզման մեջ։`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      else if (!is3Number && (checkup.taxes && checkup.taxes.decision && !checkup.taxes.decision.inspection)) {
        let title = `${checkup.CarRegNo} համարանիշի տրանսպորտային միջոցը ունի գույքահարկի պարտավորություն:`
        if (checkup.taxes && checkup.taxes.services.length && checkup.taxes.services[0].persons[0].taxes[new Date().getFullYear()].tax.debt == 0 && checkup.taxes.services[0].persons[0].taxes[new Date().getFullYear() - 1 ].tax.debt == 0) {
          title = `${new Date().getFullYear() - 1 } թվականին նախորդող տարիների համար տեղեկատվությունը բացակայում է։`
          this.$swal({
            title: '',
            text: title,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return false;
        }
        this.$swal({
          title: '',
          text: title,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
            .then(() => {
              this.copyInfoNew(checkup)
            })
      }
      else {
        this.copyInfoNew(checkup)
      }
    },

    copyInfoNew(checkup) {
      const id = uniqid()
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_TEMPORARY_ID', id)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_ADD_STATE', true)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_COPY_FORM', checkup)
      // this.$socket.emit('NEW_CHECKUP', {
      //   id: this.temporary_id,
      //   operator: this.$store.state.user,
      //   company: this.$store.state.user.company,
      //   CarRegNo: checkup.CarRegNo
      // })
      this.$bvModal.hide('modal-search')
      this.$bvModal.hide('modal-search-mobile')
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_RESULT', [])
      this.form = {
        filter: 'CarRegNo',
        value: ''
      }
    },

    copyPaymentCheckup(checkup) {
      const id = uniqid()
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_TEMPORARY_ID', id)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_ADD_STATE', true)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_COPY_PAYMENT_FORM', checkup)
      // this.$socket.emit('NEW_CHECKUP', {
      //   id: this.temporary_id,
      //   operator: this.$store.state.user,
      //   company: this.$store.state.user.company,
      //   CarRegNo: checkup.request.car_reg_no
      // })
      this.$bvModal.hide('modal-payment-search')
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_PAYMENT_RESULT', null)
      this.form = {
        filter: 'CarRegNo',
        value: ''
      }
    },

    openCheckNum(checkup) {
      this.$vs.loading()
      this.selectedCheckup = checkup
      this.selectedCheckupId = checkup._id
      this.selectedCheckupNumbers = []
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupNumbers', this.selectedCheckupId)
          .then((response) => {
            this.$vs.loading.close()
            this.selectedCheckupNumbers = response.data
            this.$bvModal.show('modal-add-c-number')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    addNewCheckupNumber() {
      this.$refs.checkupNumberValidation.validate()
          .then(success => {
            if (success) {
              this.$vs.loading()
              const payload = {
                id: this.selectedCheckupId,
                number: this.newCheckupNumber
              }
              this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/addNewCheckupNumber', payload)
                  .then(() => {
                    let data = { ...this.selectedCheckup }
                    data.CheckNo = this.newCheckupNumber
                    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SELECTED_CHECKUP', data)
                    this.$vs.loading.close()
                    this.$bvModal.hide('modal-add-c-number')
                    this.newCheckupNumber = null
                    this.selectedCheckupId = null
                    this.selectedCheckup = null
                    this.selectedCheckupNumbers = []
                    this.$bvModal.hide('modal-search')
                    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'classificator')
                  })
                  .catch(error => {
                    this.$vs.loading.close()
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },

    updateDateRange() {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', this.dateRange)
      this.getCheckups()
    },

    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9\b\cC\cX\cA\cV\cZ]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    appendFilter(field, value) {
      this.filters.fields[field] = value
      this.getCheckups()
    },

    appendDateFilter(field, value) {
      if (field === 'createdAt') {
        let start = new Date(value)
        start.setHours(0, 0, 0, 0)
        let end = new Date(value)
        end.setHours(23, 59, 59, 59)
        this.filters.startDate = start
        this.filters.endDate = end
      } else {
        this.filters.fields[field] = value
      }
      this.getCheckups()
    },

    getCustomFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = '<input class="ag-input-field-input ag-text-field-input ' + params.className + '" type="text" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomSelectFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        let select = '<select class="ag-input-field-input ag-text-field-input ' + params.className + '">'
        select += '<option value=""></option>'
        if (params.options && params.options.length > 0) {
          params.options.map((item) => {
            select += `<option value="${item.value}">${item.label}</option>`
          })
        }
        select += '</select>'
        this.eGui.innerHTML = select
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('select')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomDateFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.className = 'w-100'
        this.eGui.innerHTML = '<input data-date-format="DD/MM/YYYY" class="ag-input-field-input ag-text-field-input ' + params.className + '" type="date" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendDateFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#tech-service {
  .tech-service-table {
    max-height: calc(var(--vh, 1vh) * 100 - 10rem);
    min-height: calc(var(--vh, 1vh) * 100 - 10rem);
    height: calc(var(--vh, 1vh) * 100 - 10rem);
    padding: 0.5rem;
  }
}

.search-modal-header {
  display: flex;

  .search-header-column {
    margin-right: 10px;

    &:nth-child(1), &:nth-child(2) {
      //min-width: 265px;
    }
  }
}

.filters {
  opacity: 1;

  .daterangepicker {
    position: fixed;
    bottom: 67px;
    left: 21% !important;
  }

  .vue-daterange-picker .show-ranges.hide-calendars {
    width: 250px !important;
    min-width: 250px !important;
  }
}

.modal-title {
  color: #000;
  font-weight: bold;
}
.search-info {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #03a9f4;
    margin-right: 0.5rem;
    font-size: 19px;
  }
  span {
    color: #000;
  }
}
.tooltipButton {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
  top: -2px;
  font-size: 13px;
  left: 2px;
}

[dir] .tooltip.b-tooltip-dark .tooltip-inner {
  font-weight: bold;
  font-size: 14px;
}
.payment-services {
  display: flex;
  .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    svg {
      font-size: 35px;
    }
    .name {
      font-size: 10px;
    }
  }
}
#receipt-modal {

  .modal-header {
    display: none;
  }
  .receipt-close {
    cursor: pointer;
    font-size: 36px;
    position: absolute;
    right: 20px;
    color: #000;
  }
  opacity: 1;
  .receipt-header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    .bank-logo {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
    }
  }
  .receipt-body {
    padding: 20px 0;
    font-weight: bold;
  }
  .receipt-station {
    border-top: 1px dashed #000;
    padding: 15px 0;
    font-weight: bold;
  }
  .receipt-service {
    border-top: 1px dashed #000;
    padding: 10px 0;
    font-weight: bold;
  }
  .receipt-total {
    border-top: 2px solid #000;
    padding-top: 5px;
  }
  .receipt-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .print {
      cursor: pointer;
      font-size: 14px;
      color: #000;
    }
  }
  .receipt-column {
    color: #000;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    .info {
      opacity: 1;
    }
  }
}
.can-make-checkup {
  width: 100%;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  color: #fff;
  &.can-make-checkup-1 {
    background: var(--success);
  }
  &.can-make-checkup-0 {
    background: var(--danger);
    cursor: pointer;
  }
}
.taxes-info {
  .tax-info {
    margin-top: 10px;
    .persons {
      .person {
        .personInfo {
          color: var(--danger);
          font-weight: bold;
          text-align: center;
          &.success {
            color: var(--success);
          }
        }
        .taxes {
          width: 100%;
          margin-top: 10px;
          .tax {
            &.tax-header {
              opacity: 1;
            }
            .column {
              border: 1px solid #ccc;
              color: #000;
              font-weight: bold;
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.search-modal-body {
  th, td {
    text-align: center;
  }
}
#modal-taxes-info {
  .modal-header {
    .modal-title {
      text-align: center;
      width: 100%;
    }
  }
}
.searchSubmitButton {
  background-color: #fff!important;;
}
.vm-progress-bar__innerText {
  display: none!important;
}
.bg-blocked {
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    clear: both;
  }
}
.payment-info {
  display: flex;
  flex-direction: column;
  .car {
    font-size: 18px;
    text-align: center;
    color: #223343;
    font-weight: bold;
    //border-bottom: 2px solid #223343;
    padding-bottom: 10px;
  }
  .vehicleTypes {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-flow: wrap;
    .vehicleType {
      width: 49%;
      border-radius: 4px;
      background: #f1f1f1;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .payments {
    display: flex;
    flex-direction: column;
    .total {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #223343;
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .payment {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .payment-title {
        color: #223343;
        font-weight: bold;
        font-size: 17px;
        text-align: center;
        margin: 10px 0;
      }
      .payment-items {
        background: #f6f6f6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .item {
          display: flex;
          justify-content: space-between;
          padding: 17px 20px;
          color: #223343;
          font-size: 15px;
          font-weight: bold;
          &.withDashes {
            border-bottom: 2px dashed #ccc;
          }
        }
      }
      .paymentAction {
        margin: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          min-width: 175px;
          border-radius: 20px;
          height: 35px !important;
          font-size: 14px !important;
          font-weight: bold !important;
          &:active {
            opacity: 1;
          }
        }
      }
    }
  }
}
.totalAll {
  font-weight: bold;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 22px 0;
  background: #f7f7f7;
  border: 2px dashed #d5d3d3;
  border-radius: 10px;
  padding: 10px 0;
}
.restoreForm {
  margin-top: 25px;
  width: 100%;
  .title {
    color: #000;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .days {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .day {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #eee;
      color: #000;
      font-weight: bold;
      cursor: pointer;
      &.selected {
        background: #252c38;
        color: #fff;
      }
    }
  }
}
</style>
